<template>
  <v-app :style="appStyle" :class="[brandClass]">
    <loader v-if="!isSettingsLoaded" />
    <div v-else>
      <router-view v-slot="{ Component, route }">
        <transition name="fade">
          <component
            :is="Component"
            :key="route.fullPath"
          />
        </transition>
      </router-view>
    </div>
  </v-app>
</template>

<script>
import {
  computed,
  watchEffect
} from 'vue';
import {mapActions, mapGetters} from 'vuex';
import Loader from './components/Loader';
import { useStore } from 'vuex'

export default {
  components: {
    Loader,
  },
  setup() {
    const store = useStore()

    const getFavicon = computed(() => store.getters.getFavicon)
    const getHeadingNameCompany = computed(() => store.getters.getHeadingNameCompany)
    const isSettingsLoaded = computed(() => store.getters.isSettingsLoaded)

    // Update the document title and favicon whenever the computed properties change
    watchEffect(() => {
      if (isSettingsLoaded.value) {
        document.title = `${getHeadingNameCompany.value} - Login`;

        const existingFavicon = document.querySelector("link[rel='icon']");
        if (existingFavicon) {
          existingFavicon.setAttribute('href', getFavicon.value);
        }
        else {
          const faviconLink = document.createElement('link');
          faviconLink.setAttribute('rel', 'icon');
          faviconLink.setAttribute('type', 'image/x-icon');
          faviconLink.setAttribute('href', getFavicon.value);
          document.head.appendChild(faviconLink);
        }
      }
    });

    return {
      getHeadingNameCompany,
      getFavicon,
      isSettingsLoaded,
    };
  },
  created() {
    this.lookupSettings();
  },
  computed: {
    ...mapGetters(["getTheme", "getBrandFontFamily", "getHeadingNameProduct"]),
    appStyle() {
      return {
        "fontFamily": this.getTheme?.brandFontFamily,
        "--brand-font-family": this.getTheme?.brandFontFamily,
        "--background-image": `url(${this.getTheme?.backgroundImage})`,
        "--background-color": this.getTheme?.backgroundColor,
        "--foreground-image": `url(${this.getTheme?.foregroundImage})`,
        "--theme-primary-color": this.getTheme?.primaryColor,
        "--theme-secondary-color": this.getTheme?.secondaryColor,
        "--theme-link-color": this.getTheme?.linkColor,
      };
    },
    brandClass() {
      return `s${this.getHeadingNameProduct}`;
    }
  },
  methods: {
    ...mapActions(['lookupSettings']),
  },
};
</script>


<style lang="scss">
@import "scss/fundamental-variables.scss";
:root {
  // Stores the color into a variable that is used inside FM-variables.scss as $themePrimaryColor
  // this way, we can use the color in the style tag where needed
  --theme-primary-color: var(--theme-primary-color);
}

.sThemePrimaryColor {
  // Applies the primary color to any element with the sThemePrimaryColor class.
  color: var(--theme-primary-color);
}

body,
html {
  margin: 0;
  padding: 0;
}
</style>

<style
  scoped
  lang="scss"
>
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: 0.5s;
}

.slide-fade-enter {
  opacity: 0;
  transform: translateX(-10px);
}

.slide-fade-leave-to {
  opacity: 0;
  transform: translateX(10px);
}

</style>
