import Api from "./connect/api";
import { createStore } from 'vuex'

export const store = createStore({
  state() {
    return {
      settings: null
    }
  },
  getters: {
    getFavicon: function (state) {
      return state.settings ? state.settings.favicon : null
    },
    getHeadingNameCompany: function (state) {
      return state.settings ? state.settings.headingNameCompany : null
    },
    getHeadingNameProduct: function (state) {
      return state.settings ? state.settings.headingNameProduct : null
    },
    getHelp: function (state) {
      return state.settings ? state.settings.help : null
    },
    getLogoUrl: function (state) {
      return state.settings ? state.settings.logoUrl : null
    },
    getTheme: function (state) {
      return state.settings ? state.settings.theme : null
    },
    getBrandFontFamily: function (state) {
      return state.settings ? state.settings.brandFontFamily : null
    },
    isSettingsLoaded: function (state) {
      return state.settings !== null
    },
    getWebAddress: function (state) {
      return state.settings ? state.settings.webAddress : null
    }

  },
  actions: {
    lookupSettings({ commit }) {
      Api.get(
        "app",
        settings => {
          commit("setSettings", settings);
        },
      );
    }
  },
  mutations: {
    setSettings(state, settings) {
      state.settings = settings;
    },
  }
});
